// src/components/LuxuryBagsPro.tsx
import React from "react";

const LuxuryBagsPro: React.FC = () => {
  return (
    <div className="px-[1rem] md:px-[10rem] my-[3rem]">
      <h1 className="text-center text-2xl font-bold mb-4">GET TO KNOW</h1>
      <h2 className="text-center text-3xl font-bold text-blue-600 mb-8">
        GRANDEUR COLLECTIONS
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <img
          src="/image/a1.jpg"
          alt="Luxury Jewelries 1"
          className="w-full h-[50vh] object-cover"
        />
        <img
          src="/image/a2.jpg"
          alt="Luxury Jewelries 2"
          className="w-full h-[50vh] object-cover"
        />
        <img
          src="/image/a3.jpg"
          alt="Luxury Jewelries 3"
          className="w-full h-[50vh] object-cover"
        />
      </div>
      <p className="mt-8 text-gray-700 leading-8">
        Not only is Grandeur Collections a well-known name in the opulent realm
        of Jewelries, but it is also a trailblazer whose impact has changed the face
        of luxury fashion altogether. With more than 25 years of unrivaled
        experience, Grandeur Collections' journey has been marked by a strong
        love of luxury watches, an impressive collection of rare time pieces,
        and profound knowledge that has made it a global leader for visionary
        stylists, affluent collectors, and elite auction houses. The world has
        come to appreciate and value luxury watches and jewelries more because
        of our vision and hard work, which have turned previously owned high-end
        objects into sought-after gems. Grandeur Collections is the go-to expert
        in the luxury watch domain because of its unmatched understanding of the
        history and subtleties of the luxury market.
      </p>
      <div className="mt-12">
        <img
          src="/image/a4.jpg"
          alt="Luxury  with Client"
          className="w-full h-[50vh] md:h-[85vh]"
        />
        <p className="mt-4 text-gray-700">
          Understanding the significance of unique requirements and tastes,
          Grandeur Collections provide highly tailored services by adjusting
          advice to each individual client. 
        </p>
        <p className="mt-4 text-gray-700">
        Whether our customers are wanting to
          purchase a luxury watch for the first time, are interested in a
          particular price range, or are seasoned collectors wishing to add to
          their amazing collection, Grandeur Collections is fully dedicated to
          supporting them on their individual path.
        </p>
      </div>
    </div>
  );
};

export default LuxuryBagsPro;
