import { initializeApp } from "firebase/app";
import { Firestore, getFirestore } from 'firebase/firestore';
import { Auth, getAuth } from 'firebase/auth';

let isFirebaseInitialized = false;

const FirebaseApp = {
  apiKey: "AIzaSyB50m6d-b6ACVXqwzKTBub0aD5ZZeNaofw",
  authDomain: "gradeur-collection.firebaseapp.com",
  projectId: "gradeur-collection",
  storageBucket: "gradeur-collection.appspot.com",
  messagingSenderId: "702396745402",
  appId: "1:702396745402:web:bef2e1dcafad1fe90ca483"
};

let database: Firestore; // Explicitly define as Firestore
let auth: Auth; // Explicitly define as Auth

const initializeFirebase = () => {
  if (!isFirebaseInitialized) {
    const store = initializeApp(FirebaseApp);
    database = getFirestore(store);
    auth = getAuth(store);
    isFirebaseInitialized = true;
  }
};


initializeFirebase();

export { auth, database };
