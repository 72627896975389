// src/components/LuxuryBagsPro.tsx
import React from "react";
import "../App.css";

const LuxuryBagsPro: React.FC = () => {
  return (
    <>
      <div className="min-h-screen">
        <div className="text-center my-16 font-extrabold text-blue-900 text-sm md:text-[1.5rem]">
        SELL YOUR JEWELRIES & TIME PIECES WITH US
        </div>
        <div className="max-w-[90%] m-auto my-10  relative">
          <div className="flex  flex-col md:flex-row items-center gap-10 ">
            <div className=" w-full place-content-end">
              <img src="/image/r.jpg" alt="" className="h-[40vh]" />
            </div>
            <div className="w-full">
              <p className="leading-8">
              As an esteemed owner of a luxury timepiece, you understand the significance of entrusting its sale to a reliable and trustworthy company that possesses the necessary expertise to accurately value it. Whether it be an iconic brand such as Rolex, Patek Philippe, Cartier or Breitling, rest assured that Fine Watch Club's team of seasoned watch experts will diligently evaluate your watch's worth, ensuring that you receive the most favourable price possible.
              </p>
              <p className="leading-8">
              By choosing to sell your watch with us, you can be confident in our commitment to providing exceptional service and maximizing the value of your investment..
              </p>
            </div>
          </div>
          <br />
          <div className=" flex md:flex-row  flex-col items-center gap-10 ">
            <div className="w-full place-content-end">
              <img src="/image/r2.jpg" alt="" className="w-fit" />
            </div>
            <div className=" w-full mt-6 md:mt-20">
              <h2 className="text-lg font-semibold mb-2 text-gray-800">
                Why Choose Us?
              </h2>
              <ul className="list-disc list-inside text-gray-700">
                <li>
                  <span className="font-semibold">Expertise:</span> With years in luxury jewelries and timepieces, we price your jewelries competitively for maximum value
                </li>
                <li className="mt-2">
                  <span className="font-semibold">Exposure:</span>We showcase your jewelries and time pieces globally through our platform and partnerships.
                </li>
                <li className="mt-2">
                  <span className="font-semibold">Transparency:</span>Stay informed with regular updates from evaluation to sale.
                </li>
                <li className="mt-2">
                  <span className="font-semibold">Security:</span> Your jewelries are shipped safely with comprehensive insurance coverage.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto p-8">
        <h2 className="text-2xl font-bold text-blue-900 text-center mb-4">HOW IT WORKS</h2>
        <ol className="list-decimal list-inside mb-8">
          <li className="mb-2">
            <span className="font-bold">Submit Your Jewelries:</span> Provide details
            and photos online or visit us for a consultation.
          </li>
          <li className="mb-2">
            <span className="font-bold">Evaluation:</span> We assess based on
            brand, condition, and market demand.
          </li>
          <li className="mb-2">
            <span className="font-bold">Agreement:</span> Sign our consignment
            terms, including pricing and commission.
          </li>
          <li className="mb-2">
            <span className="font-bold">Sell:</span> We contact you to tell us
            how you wish to be paid once your Jewelries sell.
          </li>
        </ol>
        <p className="mb-4">
          Get Started Today! <br />
          Join others who trust us to consign their luxury jewelries. Start by
          filling our online form or visiting our boutique.
        </p>
        <p className="mb-4">
          Let us help you find a new home for your luxury jewelries while you enjoy
          the convenience and benefits of consignment.
        </p>
        <p className="mb-8">
          Discover a seamless way to consign your luxury jewelries with Luxury jewelries
          Pro. Unlock value and space in your collection effortlessly.
        </p>
        <p className="text-center">
          For questions, visit our "Contact" page to reach out today.
        </p>
      </div>
    </>
  );
};

export default LuxuryBagsPro;
