import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-black text-white py-10 px-6">
      <div className="container mx-auto flex flex-col md:flex-row justify-between">
        <div className="flex flex-col space-y-2">
          <Link to="/shop" className="hover:underline pb-2">SHOP</Link>
          <Link to="/consign" className="hover:underline pb-2">CONSIGN</Link>
          <Link to="/about" className="hover:underline pb-2">ABOUT US</Link>
          <Link to="/contact" className="hover:underline pb-2">CONTACT US</Link>
        </div>
        <div className="flex flex-col space-y-2 mt-4 md:mt-0">
          <Link to="/instagram" className="hover:underline pb-2">INSTAGRAM</Link>
          <Link to="/facebook" className="hover:underline pb-2">FACEBOOK</Link>
          <Link to="/pinterest" className="hover:underline pb-2">PINTEREST</Link>
          <Link to="/tiktok" className="hover:underline pb-2">TIKTOK</Link>
        </div>
        <div className="mt-4 md:mt-0">
          <h4 className="mb-2">PAYMENT METHODS</h4>
          <div className=" space-x-2">
           <div className="">
           <img src="/image/pay2.jpeg" alt="PayPal" className="h-9" />
           </div>
           <br />
            <div className="flex space-x-2">
            <img src="/image/bank.png" alt="Bank" className="h-9" />
            <img src="/image/pay.png" alt="PayPal" className="h-9" />
            <img src="/image/ve.png" alt="Venmo" className="h-9" />
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <p>Copyright &copy; Grandeur Collections</p>
      </div>
    </footer>
  );
}

export default Footer;
