import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../styles/contact.css";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { getRandomString } from "../Services/Utility";
import { database } from "../firebase";
import { toast } from "react-toastify";

function Conttact() {
  const [name, setName] = useState("");
  const [email, setemail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setloading] = useState(false);
  const handeleSend = async () => {
    if (!name || !email || !message) {
      toast.error("Enter all required fill");
      return;
    }
    setloading(true);
    const data = {
      id: getRandomString(
        35,
        "qwertyuiopasdfhjklzxcvbnmQWERTYUIOPASDFHJKLZXCVBNM234567890"
      ),
      name,
      email,
      message,
      isRead: false,
      createdAt: serverTimestamp(),
    };
    await setDoc(doc(database, "contact", data.id), data);
    toast.success("Message Sent Successfully");
    setloading(false);
  };
  return (
    <div>
      <Navbar />
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white p-8 rounded-md shadow-md w-full md:max-w-[70%]">
          <h2 className="text-center text-2xl font-semibold mb-4">
            WANT TO GET IN TOUCH?
          </h2>
          <h3 className="text-center text-xl font-bold text-black mb-6">
            CONTACT US TODAY
          </h3>
          <p className="text-center mb-2">
            You can reach us via phone or text at{" "}
            <a href="tel:+2244424355" className="text-black">
            +1(973) 750-8629
            </a>
          </p>

          <p className="text-center mb-2">
           Visit us @{" "}
           21 Crosby Street
           New York, NY 10013
          </p>
          <p className="text-center mb-6">
            <a
              href="mailto:contact@grandeurcollections.shop"
              className="text-black"
            >
              contact@grandeurcollections.shop
            </a>
          </p>
          <p className="text-center mb-6">
            You can also fill out the form below or message us on{" "}
            <a href="https://wa.me/19737508629" className="text-black">
              WhatsApp
            </a>
          </p>
          <form className="space-y-4">
            <div className="flex space-x-4">
              <input
                onChange={(event) => setName(event.target.value)}
                type="text"
                placeholder="Name"
                className="w-1/2 p-2 border border-gray-300 rounded-md"
              />
              <input
                required
                onChange={(event) => setemail(event.target.value)}
                type="email"
                placeholder="E-mail"
                className="w-1/2 p-2 border border-gray-300 rounded-md"
              />
            </div>
            <textarea
              onChange={(event) => setMessage(event.target.value)}
              required
              placeholder="Message"
              className="w-full p-2 border border-gray-300 rounded-md"
              rows={5}
            ></textarea>
            <button className="w-full p-2 bg-black text-white rounded-md" disabled={loading} onClick={handeleSend}>
              {loading ? "Please wait ... " : "SEND MESSAGE"}
            </button>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Conttact;
