// src/components/ProductCard.tsx
import React from 'react';
import { formatNumber } from '../Services/Utility';

interface ProductCardProps {
  imageUrl: string;
  productName: string;
  productPrice: number;
  onBuyNow: () => void;
}

function truncateProductName(productName:string, maxLength = 30) {
  if (productName.length > maxLength) {
    return productName.substring(0, maxLength) + "...";
  }
  return productName;
}

const ProductCard: React.FC<ProductCardProps> = ({
  imageUrl,
  productName,
  productPrice,
  onBuyNow,
}) => {
  
  return (
    <div className=" bg-white border border-gray-200  overflow-hidden">
      <img src={imageUrl} alt={productName} className="w-full h-[30vh]" /> 
      <div className="p-4">
        <h2 className="text-sm">{truncateProductName(productName)}</h2>
        <p className="text-sm text-black font-normal mt-4">$ {formatNumber(productPrice)}</p>
        <button
          className="mt-4 bg-[#b81d33] text-white px-3 py-1 rounded hover:bg-orange-900 transition duration-300"
          onClick={onBuyNow}
        >
          Buy Now
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
